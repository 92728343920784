import uiUxOne from "./assets/ui-ux-project-one.svg";
import uiUxTwo from "./assets/ui-ux-project-two.svg";
import summ from "./assets/summ.png";
import projectsIdea from "./assets/projectsIdea.png";
import none from "./assets/none.png";
import webThree from "./assets/web-project-three.jpeg";

interface Project {
  id: number;
  category: string;
  img: string;
  title: string;
  link: string;
}

export const projects: Project[] = [
  {
    id: 0,
    category: "reactJs",
    img: "https://raw.githubusercontent.com/YCIT-030-Project/Games_Store/main/screenshots/HomePage.png",
    title: "Video Games E-commerce Website",
    link: "https://github.com/YCIT-030-Project/Games_Store",
  },
  {
    id: 1,
    category: "reactJs",
    img: "https://media.discordapp.net/attachments/1127023281246060607/1131811806264959026/image.png?width=1738&height=936",
    title: "Voting app",
    link: "https://github.com/YCIT099-Capstone-Project/poll-voting-app_v2/",
  },
  {
    id: 2,
    category: "reactJs",
    img: summ,
    title: "Summarize Articles with OpenAi",
    link: "https://summaidark.netlify.app/",
  },
  {
    id: 3,
    category: "reactJs",
    img: projectsIdea,
    title: "Inspire Your Next Tech Project with OpenAi",
    link: "https://projectsidea.netlify.app/",
  },
  {
    id: 4,
    category: "reactJs",
    img: none,
    title: "Currency-Converter-react",
    link: "https://github.com/aldukhn/Currency-Converter-react",
  },
  {
    id: 5,
    category: "reactJs",
    img: none,
    title: "BMI Calculator",
    link: "https://github.com/aldukhn/bmi_calc",
  },
  {
    id: 6,
    category: "laravel",
    img: "https://raw.githubusercontent.com/aldukhn/Emp_mngmt/master/4.png",
    title: "employment management system",
    link: "https://github.com/aldukhn/Emp_mngmt",
  },
];
