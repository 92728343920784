// assets
import contactPageImg from "../assets/contact-page.svg";
import contactIllustration from "../assets/contact-illustration.svg";
import React, { useState } from "react";

// components
import { Button, LabelInput, Reveal } from "../components";

// framer-motion
import { motion } from "framer-motion";

// utils
import { fadeIn, scale } from "../utils/variants";
import { transition } from "../utils/transition";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const [response, setResponse] = useState(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async () => {
    const { name, email, message } = formData;

    setFormErrors({
      name: name === "",
      email: email === "" || !validateEmail(email),
      message: message === "",
    });

    if (name && email && message && validateEmail(email)) {
      try {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const data = await res.json();
        setResponse(data);

        // Clear the form here
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <div
      id="contact"
      className="min-h-screen flex items-center justify-center relative"
      style={{
        background: `url(${contactPageImg})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="max-w-screen-2xl flex flex-col  xl:flex-row xl:justify-between 
    items-center xl:items-start gap-12 w-full pt-20 pb-20 sm:pb-16 px-12"
      >
        <div className="flex-1 flex flex-col gap-4">
          <Reveal>
            <h2 className="text-center xl:text-start tex-4xl sm:text-5xl lg:text-[64px] font-bold text-textPrimary">
              Contact <span className="text-secondary">me</span>
            </h2>
          </Reveal>

          <motion.img
            variants={scale()}
            transition={transition()}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            src={contactIllustration}
            alt=""
            className="max-h-[348px]"
          />
        </div>

        <motion.div
          variants={fadeIn("up")}
          transition={transition()}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          className="flex-1 flex flex-col gap-6 w-full max-w-[696px]"
        >
          <div className="flex flex-col sm:flex-row items-center gap-6">
            <LabelInput
              labelText="Your Name"
              placeholderText="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={formErrors.name}
            />
            <LabelInput
              labelText="Your Email"
              placeholderText="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={formErrors.email}
            />
          </div>

          <div className="flex flex-col sm:flex-row items-center gap-6">
            <LabelInput
              labelText="Your message"
              placeholderText="Message"
              textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              isInvalid={formErrors.message}
            />
          </div>

          <Button onClick={handleSubmit} secondary>
            Send Message
          </Button>
        </motion.div>
      </div>

      <div className="absolute bottom-0 left-0 w-full h-[1px] bg-divider" />
    </div>
  );
};

export default Contact;
