// mui
import { Tooltip } from "@mui/material";

// react
import { FC } from "react";

interface SocialMediaIconProps {
  IconComponent: React.ElementType;
  title: string;
}

const SocialMediaIcon: FC<SocialMediaIconProps> = ({
  IconComponent,
  title,
}) => {
  return (
    <Tooltip title={title} placement="bottom" arrow>
      <div
        className="flex items-center justify-center h-12 w-12 rounded-full bg-icons cursor-pointer
      hover:bg-secondary transition-all ease-linear duration-300"
      >
        <IconComponent sx={{ color: "white" }} />
      </div>
    </Tooltip>
  );
};

export default SocialMediaIcon;
